import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";
import SERVER_URL from "../../constants/constants";
import fetchUtility from "../fetchUtility";

const initialState = {
  loginDetail: Cookies.get("token") ? JSON.parse(Cookies.get("token")) : null,
  userProfile: Cookies.get("user") ? JSON.parse(Cookies.get("user")) : null,
  loading: false,
  btnLoader: false,
  status: "",
  statusCode: null,
  error: "",
  mfaError: "",
  isRemember: false,
  expiryTime: null,
};

export const getLoginActionCreator = createAsyncThunk(
  "users/login",
  async (params) => {
    let API_URL = `${SERVER_URL.LOGIN}`;
    const { data } = await fetchUtility(
      "post",
      `${API_URL}`,
      params.body,
      false,
      { successToast: false, errorToast: false },
    );
    return data;
  },
);

export const getSSOLoginActionCreator = createAsyncThunk(
  "users/sso/login",
  async (params, { rejectWithValue }) => {
    const flag =
      //eslint-disable-next-line no-undef
      String(process.env.REACT_APP_NODE_ENV) === "development" ? 1 : 0;

    let API_URL = `${SERVER_URL.SSO_LOGIN}?email=${encodeURIComponent(params.body.email)}&flag=${flag}`;
    try {
      const { data } = await fetchUtility("get", `${API_URL}`, null, false, {
        successToast: false,
        errorToast: false,
      });
      return data;
    } catch (error) {
      let errResponses = error.response?.data;
      const errors = {
        // username: error.response?.data?.errors?.username?.[0] || null,
        email:
          (errResponses && errResponses?.errors
            ? errResponses.errors?.email?.[0]
            : errResponses?.email?.[0]) || null,
        mfa:
          (errResponses && errResponses?.errors
            ? errResponses.errors?.mfa?.[0]
            : errResponses?.mfa?.[0]) || null,

        // password: error.response?.data?.errors?.password?.[0] || null,
      };
      return rejectWithValue(errors);
    }
  },
);

export const getSSOCallbackActionCreator = createAsyncThunk(
  "users/sso/callback",
  async (params) => {
    const flag =
      //eslint-disable-next-line no-undef
      String(process.env.REACT_APP_NODE_ENV) === "development" ? 1 : 0;
    let API_URL = `${SERVER_URL.SSO_CALLBACK}?code=${params.code}&state=${params.state}&mail_id=${params.mail_id}&flag=${flag}`;
    try {
      const { data } = await fetchUtility("get", `${API_URL}`, null, false, {
        successToast: false,
        errorToast: false,
      });
      return data;
    } catch (error) {
      console.error("Error during API call", error);
      throw error;
    }
  },
);

export const getUserDetailActionCreator = createAsyncThunk(
  "users/profile",
  async () => {
    let API_URL = `${SERVER_URL.USER_PROFILE}`;
    const { data } = await fetchUtility("get", `${API_URL}`);
    return data;
  },
);

export const getMFAStatusActionCreator = createAsyncThunk(
  "users/mfa/status",
  async (params) => {
    let API_URL = `${SERVER_URL.MFA_STATUS}`;
    const { data } = await fetchUtility(
      "post",
      `${API_URL}`,
      params.body,
      false,
      { successToast: false, errorToast: false },
    );
    return data;
  },
);

export const registerMFAActionCreator = createAsyncThunk(
  "users/mfa/register",
  async (params) => {
    let API_URL = `${SERVER_URL.MFA_REGISTER}`;
    const { data } = await fetchUtility("post", `${API_URL}`, params, false, {
      successToast: false,
      errorToast: false,
    });
    return data;
  },
);

export const verifyMFAActionCreator = createAsyncThunk(
  "users/mfa/verify",
  async (params) => {
    let API_URL = `${SERVER_URL.MFA_VERIFY}`;
    const { data } = await fetchUtility(
      "post",
      `${API_URL}`,
      params.body,
      false,
      { successToast: false, errorToast: false },
    );
    return data;
  },
);

export const removeMFAActionCreator = createAsyncThunk(
  "users/mfa/disable",
  async (id) => {
    let API_URL = `${SERVER_URL.MFA_REMOVE}`;
    const { data } = await fetchUtility(
      "post",
      `${API_URL}${id}/`,
      null,
      true,
      { successToast: false, errorToast: false },
    );
    return data;
  },
);

export const logoutActionCreator = createAsyncThunk(
  "users/logout",
  async (params) => {
    let API_URL = `${SERVER_URL.LOGOUT}`;
    const { data } = await fetchUtility(
      "post",
      `${API_URL}`,
      params.body,
      true,
      params.toast
        ? params.toast
        : {
            successToast: true,
            errorToast: false,
          },
    );
    return data;
  },
);

export const refreshTokenAction = createAsyncThunk(
  "token/refresh",
  async (params) => {
    let API_URL = `${SERVER_URL.REFRESH_TOKEN}`;
    const { data } = await fetchUtility(
      "post",
      `${API_URL}`,
      params.body,
      false,
      { successToast: false, errorToast: false },
    );
    return data;
  },
);

const loginSlice = createSlice({
  name: "login",
  initialState,
  extraReducers: (builder) => {
    /*login api handler*/
    builder.addCase(getLoginActionCreator.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getLoginActionCreator.fulfilled, (state, action) => {
      state.loading = false;
      state.loginDetail = action.payload;
      state.error = "";
      const encryptedLoginDetails = state?.loginDetail;
      Cookies.set("token", JSON.stringify(encryptedLoginDetails));
    });
    builder.addCase(getLoginActionCreator.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    /*user profile api handler*/
    builder.addCase(getUserDetailActionCreator.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getUserDetailActionCreator.fulfilled, (state, action) => {
      state.loading = false;
      state.userProfile = action.payload;
      state.error = "";
      const encryptedUserDetails = state?.userProfile;
      if (state.expiryTime && state.isRemember) {
        Cookies.set("user", JSON.stringify(encryptedUserDetails), {
          expires: state.expiryTime?.exp / (60 * 60 * 24 * 1000),
        });
      } else {
        Cookies.set("user", JSON.stringify(encryptedUserDetails));
      }
    });
    builder.addCase(getUserDetailActionCreator.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    /*mfa api handler*/
    builder.addCase(getMFAStatusActionCreator.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getMFAStatusActionCreator.fulfilled, (state) => {
      state.loading = false;
      state.error = "";
    });
    builder.addCase(getMFAStatusActionCreator.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    builder.addCase(registerMFAActionCreator.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(registerMFAActionCreator.fulfilled, (state) => {
      state.loading = false;
      state.mfaError = "";
    });
    builder.addCase(registerMFAActionCreator.rejected, (state, action) => {
      state.loading = false;
      state.mfaError = action.error.message;
    });
    builder.addCase(verifyMFAActionCreator.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(verifyMFAActionCreator.fulfilled, (state, action) => {
      state.isRemember = action?.meta?.arg?.body?.remember_me;
      state.loading = false;
      state.loginDetail = action.payload.data;
      state.mfaError = "";
      const encryptedUserDetails = state?.loginDetail;
      state.expiryTime = jwtDecode(state.loginDetail?.refresh);
      if (state.expiryTime && state.isRemember) {
        Cookies.set("token", JSON.stringify(encryptedUserDetails), {
          expires: state.expiryTime?.exp / (60 * 60 * 24 * 1000),
        });
      } else {
        Cookies.set("token", JSON.stringify(encryptedUserDetails));
      }
    });
    builder.addCase(verifyMFAActionCreator.rejected, (state, action) => {
      state.loading = false;
      state.mfaError = action.error.message;
    });
    //refresh token
    builder.addCase(refreshTokenAction.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(refreshTokenAction.fulfilled, (state, action) => {
      state.loading = false;
      state.loginDetail.access = action.payload?.access;
      state.mfaError = "";
      const encryptedUserDetails = state?.loginDetail;
      state.expiryTime = jwtDecode(state.loginDetail?.refresh);
      if (state.expiryTime && state.isRemember) {
        Cookies.set("token", JSON.stringify(encryptedUserDetails), {
          expires: state.expiryTime?.exp / (60 * 60 * 24 * 1000),
        });
      } else {
        Cookies.set("token", JSON.stringify(encryptedUserDetails));
      }
    });
    builder.addCase(refreshTokenAction.rejected, (state, action) => {
      state.loading = false;
      state.mfaError = action.error.message;
    });
    //logout
    builder.addCase(logoutActionCreator.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(logoutActionCreator.fulfilled, (state) => {
      localStorage.setItem("logout-event", Date.now().toString());

      Cookies.remove("user");
      Cookies.remove("token");
      Cookies.remove("skip_alert");
      state.loginDetail = null;
      state.userProfile = null;
      state.loading = false;
      state.error = "";
      state.expiryTime = null;
      state.isRemember = false;
    });
    builder.addCase(logoutActionCreator.rejected, (state) => {
      Cookies.remove("user");
      Cookies.remove("token");
      Cookies.remove("skip_alert");
      state.loading = false;
      state.error = "";
      state.loginDetail = null;
      state.userProfile = null;
      state.expiryTime = null;
      state.isRemember = false;
    });
    builder.addCase(getSSOLoginActionCreator.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getSSOLoginActionCreator.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(getSSOCallbackActionCreator.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getSSOCallbackActionCreator.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getSSOLoginActionCreator.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getSSOCallbackActionCreator.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(removeMFAActionCreator.pending, (state) => {
      state.btnLoader = true;
    });
    builder.addCase(removeMFAActionCreator.fulfilled, (state) => {
      state.btnLoader = false;
    });
    builder.addCase(removeMFAActionCreator.rejected, (state) => {
      state.btnLoader = false;
    });
  },
});

export default loginSlice.reducer;
