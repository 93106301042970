import { configureStore } from "@reduxjs/toolkit";
import LoginSlices from "./slices/LoginSlices";
import UserSlices from "./slices/UserSlices";
import ChangePasswordSlices from "./slices/ChangePasswordSlices";
import CategorySlices from "./slices/CategorySlices";
import PropertySlices from "./slices/PropertySlices";
import VendorSlices from "./slices/VendorSlices";
import ProductSlices from "./slices/ProductSlices";
import SubCategorySlices from "./slices/SubCategorySlices";
import BudgetSlice from "./slices/BudgetSlice";

const store = configureStore({
  reducer: {
    login: LoginSlices,
    user: UserSlices,
    changePassword: ChangePasswordSlices,
    category: CategorySlices,
    property: PropertySlices,
    vendor: VendorSlices,
    product: ProductSlices,
    subCategory: SubCategorySlices,
    budget: BudgetSlice,
  },
});
export default store;
