import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import styles from "./SSO.module.scss";

const SSOCallback = () => {
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const code = params.get("code");
    const state = params.get("state");
    if (code && state) {
      window.opener.postMessage(
        { type: "SSO_CALLBACK_SUCCESS", payload: { code, state } },
        "*",
      );
      window.close();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.container}>
      <h1 className={styles.heading}>Authenticating...</h1>
      <p className={styles.message}>
        Please wait while we complete the authentication process.
      </p>
    </div>
  );
};

export default SSOCallback;
