import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import SERVER_URL from "../../constants/constants";
import fetchUtility from "../fetchUtility";

const initialState = {
  vendorList: [],
  btnLoader: false,
  loading: false,
  status: "",
  error: "",
};

export const getVendorListAction = createAsyncThunk("vendor/list", async () => {
  let API_URL = `${SERVER_URL.VENDOR}`;
  const { data } = await fetchUtility("get", `${API_URL}`);
  return data;
});

export const saveVendorAction = createAsyncThunk(
  "vendor/save",
  async (params, { rejectWithValue }) => {
    const API_URL = `${SERVER_URL.CREATE_VENDOR}`;

    try {
      const { data } = await fetchUtility(
        "post",
        `${API_URL}`,
        params.body,
        true,
        { successToast: true, errorToast: false },
      );
      return data; // If the request is successful, return the data
    } catch (error) {
      const errors = {
        vendor_name: error.response?.data?.errors?.vendor_name?.[0] || null,
      };
      return rejectWithValue(errors);
    }
  },
);

export const updateVendorAction = createAsyncThunk(
  "vendor/update",
  async (params, { rejectWithValue }) => {
    try {
      const API_URL = `${SERVER_URL.VENDOR}`;
      const { data } = await fetchUtility(
        "patch",
        `${API_URL}${params.body.vendor_id}/`,
        params.body,
        true,
        { successToast: true, errorToast: false },
      );
      return data;
    } catch (error) {
      const errors = {
        vendor_name: error.response?.data?.errors?.vendor_name?.[0] || null,
      };
      return rejectWithValue(errors);
    }
  },
);

export const deleteVendorAction = createAsyncThunk(
  "vendor/delete",
  async (id) => {
    let API_URL = `${SERVER_URL.VENDOR}`;
    const { data } = await fetchUtility(
      "delete",
      `${API_URL}${id}/`,
      null,
      true,
      { successToast: false, errorToast: true },
    );
    return data;
  },
);

const vendorSlice = createSlice({
  name: "vendor",
  initialState,
  reducers: {
    setBtnLoaderFalse: (state) => {
      state.btnLoader = false;
    },
  },
  extraReducers: (builder) => {
    //source List
    builder.addCase(getVendorListAction.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getVendorListAction.fulfilled, (state, action) => {
      state.loading = false;
      state.vendorList = action.payload?.data;
      state.error = "";
    });
    builder.addCase(getVendorListAction.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    //create new source
    builder.addCase(saveVendorAction.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(saveVendorAction.fulfilled, (state) => {
      state.loading = false;
      state.error = "";
    });
    builder.addCase(saveVendorAction.rejected, (state, action) => {
      state.loading = false;
      // state.error = action.error.message;
      state.error = action.payload;
    });
    //update source
    builder.addCase(updateVendorAction.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateVendorAction.fulfilled, (state) => {
      state.loading = false;
      state.error = "";
    });
    builder.addCase(updateVendorAction.rejected, (state, action) => {
      state.loading = false;
      // state.error = action.error.message;
      state.error = action.payload;
    });
    //delete source
    builder.addCase(deleteVendorAction.pending, (state) => {
      state.btnLoader = true;
    });
    builder.addCase(deleteVendorAction.fulfilled, (state) => {
      state.error = "";
    });
    builder.addCase(deleteVendorAction.rejected, (state, action) => {
      state.btnLoader = false;
      state.error = action.error.message;
    });
  },
});
export default vendorSlice.reducer;
export const { setBtnLoaderFalse } = vendorSlice.actions;
