import React, { Suspense, useEffect } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { jwtDecode } from "jwt-decode";
import { toast } from "react-toastify";
import { commonRoutes, routes, authroutes } from "./route";
import { useIdle } from "./SessionInactivity";
import AccessDenied from "./pages/auth/access-denied/AccessDenied";
import Header from "./layouts/header/Header";
import NotFound from "./pages/404/NotFound";
import InjectAxiosInterceptors from "./components/injectAxiosInterceptor/InjectAxiosInterceptors";
import Login from "./pages/auth/login/Login";
import ChangePassword from "./pages/auth/changePassword/ChangePassword";
import SkipLink from "./components/skipLink/SkipLink";
import { refreshTokenAction } from "./redux/slices/LoginSlices";
import Loader from "./components/loader/Loader";
import "./App.scss";

function App() {
  const location = useLocation();
  const userProfile = useSelector((state) => state.login.userProfile);
  const loginToken = useSelector((state) => state.login.loginDetail);
  // const [innerHeight, setInnerHeight] = useState(window.innerHeight - 100);
  const dispatch = useDispatch();
  const isIdle = useIdle(3600000);
  const definedRoutes = [
    ...commonRoutes.map(({ path }) => path),
    ...routes.map(({ path }) => path),
    ...authroutes.map(({ path }) => path),
  ];

  useEffect(() => {
    if (loginToken) {
      const decodeAccess = jwtDecode(loginToken?.access);
      const decodeRefresh = jwtDecode(loginToken?.refresh);
      const duration =
        (decodeAccess?.exp - Math.floor(Date.now() / 1000)) * 1000;
      if (duration - 5000 < decodeRefresh?.exp) {
        const timer = setTimeout(() => {
          dispatch(
            refreshTokenAction({ body: { refresh: loginToken?.refresh } }),
          );
        }, duration);
        return () => clearTimeout(timer);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isIdle, loginToken]);

  // useEffect(() => {
  //   const updatePageSize = () => {
  //     setInnerHeight(window.innerHeight - 68);
  //   };

  //   updatePageSize(); // Initial check
  //   window.addEventListener("resize", updatePageSize); // Listen for resize
  //   return () => window.removeEventListener("resize", updatePageSize); // Cleanup
  // }, [innerHeight]);

  useEffect(() => {
    // Clear all active toastrs when the route changes
    if (location.pathname !== "/login") toast.dismiss();
    else {
      setTimeout(() => toast.dismiss(), 1000);
    }
  }, [location]);

  const publicRoutes = (route) => {
    return route.map(({ path, component }) => {
      if (path !== "/login") {
        return <Route key={path} path={path} element={component} exact />;
      }
    });
  };
  const authRoutes = (route) => {
    return route.map(({ path, component, isLogged }) => {
      if (!isLogged) {
        return <Route key={path} path={path} element={component} exact />;
      }
    });
  };
  const privateRoutes = (route) => {
    let profile = JSON.parse(JSON.stringify(userProfile)) || {};
    return route.map(({ path, component }) => {
      if (profile?.is_superuser) {
        return <Route key={path} path={path} element={component} exact />;
      } else {
        return (
          <Route key={path} path={path} element={<AccessDenied />} exact />
        );
      }
    });
  };

  useEffect(() => {}, []);

  const shouldShowHeader = () => {
    if (!userProfile) return false;
    return definedRoutes.some((route) => {
      const pattern = route.replace(/:\w+/g, "[^/]+");
      const regex = new RegExp(`^${pattern}$`);
      return regex.test(location.pathname);
    });
  };

  return (
    <div className="App">
      <InjectAxiosInterceptors />

      {/* Add SkipLink component */}
      <SkipLink />

      {shouldShowHeader() && <Header />}
      <Suspense fallback={<Loader />}>
        {/* {console.log("style={{ height: `${innerHeight}px` }}", innerHeight)} */}
        <main id="mainContent">
          <Routes>
            {userProfile && (
              <>
                {publicRoutes(commonRoutes)}
                <Route
                  path="/update-password"
                  element={<ChangePassword />}
                  exact
                />
              </>
            )}
            <Route
              path="/login"
              element={userProfile ? <Navigate to="/" /> : <Login />}
            />
            {userProfile ? (
              <>{privateRoutes(routes)}</>
            ) : (
              <>
                <Route path="*" element={<Navigate to="/login" />} />
                {authRoutes(authroutes)}
                {loginToken && (
                  <Route
                    path="/update-password"
                    element={<ChangePassword />}
                    exact
                  />
                )}
              </>
            )}
            <Route path="/access-denied" element={<AccessDenied />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </main>
      </Suspense>
    </div>
  );
}

export default App;
