import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import SERVER_URL from "../../constants/constants";
import fetchUtility from "../fetchUtility";

const initialState = {
  userList: [],
  userDetails: null,
  loading: false,
  btnLoader: false,
  status: "",
  statusCode: null,
  error: "",
};

export const getUserListActionCreator = createAsyncThunk(
  "user/list",
  async () => {
    let API_URL = `${SERVER_URL.USER_LIST}`;
    const { data } = await fetchUtility("get", `${API_URL}`);
    return data;
  },
);

// export const saveUserActionCreator = createAsyncThunk(
//   "user/register",
//   async (params) => {
//     let API_URL = `${SERVER_URL.CREATE_USER}`;
//     const { data } = await fetchUtility("post", `${API_URL}`, params.body);
//     return data;
//   },
// );

export const saveUserActionCreator = createAsyncThunk(
  "user/register",
  async (params, { rejectWithValue }) => {
    const API_URL = `${SERVER_URL.CREATE_USER}`;

    try {
      const { data } = await fetchUtility(
        "post",
        `${API_URL}`,
        params.body,
        true,
        { successToast: true, errorToast: false },
      );
      return data; // If the request is successful, return the data
    } catch (error) {
      const errors = {
        // username: error.response?.data?.username?.[0] || null,
        email: error.response?.data?.errors?.email?.[0] || null,
        first_name: error.response?.data?.errors?.first_name?.[0] || null,
        last_name: error.response?.data?.errors?.last_name?.[0] || null,
        // password: error.response?.data?.password?.[0] || null,
      };
      return rejectWithValue(errors);
    }
  },
);

export const updateProfileCreator = createAsyncThunk(
  "users/profile/update/",
  async (params, { rejectWithValue }) => {
    try {
      const API_URL = `${SERVER_URL.UPDATE_PROFILE}`;
      const { data } = await fetchUtility(
        "put",
        `${API_URL}`,
        params.body,
        true,
        { successToast: false, errorToast: false },
      );
      return data;
    } catch (error) {
      const errors = {
        // username: error.response?.data?.username?.[0] || null,
        email: error.response?.data?.email?.[0] || null,
        first_name: error.response?.data?.first_name?.[0] || null,
        last_name: error.response?.data?.last_name?.[0] || null,
        // password: error.response?.data?.password?.[0] || null,
      };
      return rejectWithValue(errors);
    }
  },
);

export const updateUserActionCreator = createAsyncThunk(
  "users/update",
  async ({ params, showLoader = true }, { rejectWithValue }) => {
    try {
      const API_URL = `${SERVER_URL.UPDATE_USER}`;
      const { data } = await fetchUtility(
        "put",
        `${API_URL}${params.body.id}/`,
        params.body,
        true,
        { successToast: false, errorToast: false },
      );
      return { data, showLoader };
    } catch (error) {
      // if (error.response?.data?.password?.[0]) {
      //   return rejectWithValue("Password cannot be empty");
      // } else if (error.response?.data?.username?.[0]) {
      //   return rejectWithValue(
      //     error.response?.data?.username?.[0] ||
      //       "This username is already taken.",
      //   );
      // } else if (error.response?.data?.email?.[0]) {
      //   return rejectWithValue(
      //     error.response?.data?.email?.[0] || "This email is already taken.",
      //   );
      // } else {
      //   return rejectWithValue(
      //     error.message || "An error occurred while creating the user",
      //   );
      // }
      const errors = {
        // username: error.response?.data?.username?.[0] || null,
        email: error.response?.data?.email?.[0] || null,
        first_name: error.response?.data?.first_name?.[0] || null,
        last_name: error.response?.data?.last_name?.[0] || null,
        // password: error.response?.data?.password?.[0] || null,
      };
      return rejectWithValue(errors);
    }
  },
);

export const deleteUserActionCreator = createAsyncThunk(
  "user/delete",
  async (id) => {
    let API_URL = `${SERVER_URL.DELETE_USER}`;
    const { data } = await fetchUtility(
      "delete",
      `${API_URL}${id}/`,
      null,
      true,
      { successToast: false, errorToast: false },
    );
    return data;
  },
);

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setBtnLoaderFalse: (state) => {
      state.btnLoader = false;
    },
  },
  extraReducers: (builder) => {
    //user List
    builder.addCase(getUserListActionCreator.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getUserListActionCreator.fulfilled, (state, action) => {
      state.loading = false;
      state.userList = action.payload;
      state.error = "";
    });
    builder.addCase(getUserListActionCreator.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    //create new user
    builder.addCase(saveUserActionCreator.pending, (state) => {
      state.btnLoader = true;
    });
    builder.addCase(saveUserActionCreator.fulfilled, (state) => {
      state.error = "";
    });
    builder.addCase(saveUserActionCreator.rejected, (state, action) => {
      state.btnLoader = false;
      // state.error = action.error.message;
      state.error = action.payload;
    });
    //update user
    builder.addCase(updateUserActionCreator.pending, (state, action) => {
      const showLoader = action.meta.arg.showLoader ?? true;
      state.btnLoader = showLoader;
    });
    builder.addCase(updateUserActionCreator.fulfilled, (state) => {
      state.error = "";
    });
    builder.addCase(updateUserActionCreator.rejected, (state, action) => {
      state.btnLoader = false;
      // state.error = action.error.message;
      state.error = action.payload;
    });
    //update user profile
    builder.addCase(updateProfileCreator.pending, (state) => {
      state.btnLoader = true;
    });
    builder.addCase(updateProfileCreator.fulfilled, (state) => {
      state.error = "";
    });
    builder.addCase(updateProfileCreator.rejected, (state, action) => {
      state.btnLoader = false;
      state.error = action.payload;
    });
    //delete user
    builder.addCase(deleteUserActionCreator.pending, (state) => {
      state.btnLoader = true;
    });
    builder.addCase(deleteUserActionCreator.fulfilled, (state) => {
      state.error = "";
    });
    builder.addCase(deleteUserActionCreator.rejected, (state, action) => {
      state.btnLoader = false;
      state.error = action.payload;
    });
  },
});
export default userSlice.reducer;
export const { setBtnLoaderFalse } = userSlice.actions;
