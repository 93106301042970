import React, { forwardRef } from "react";
import { IoEyeOffOutline, IoEyeOutline } from "react-icons/io5";
import "./InputField.scss";

const InputFields = forwardRef(
  (
    {
      label,
      type,
      name,
      placeholder,
      errors,
      onChange,
      value = "",
      handleKeyDown,
      showPasswordToggle,
      onTogglePassword,
      showPassword,
      title,
      additionalClass,
      autoComplete,
      ariaLabel,
      ariaDescribedby,
      enableClear = false,
      isDisabled = false,
      readOnly = false,
    },
    ref,
  ) => {
    const handleClear = () => {
      onChange({ target: { name, value: "" } });
      handleKeyDown({ key: "Enter" }, true);
    };
    return (
      <>
        {label && (
          <label className="label" htmlFor={name}>
            {label}
          </label>
        )}
        <div className={`inputWrapper ${enableClear ? "flex-input" : ""}`}>
          {type && type === "textarea" ? (
            <textarea
              rows="4"
              cols="50"
              id={name}
              ref={ref} // Forwarded ref for react-hook-form
              type={type}
              name={name}
              placeholder={placeholder}
              className={`inputs ${errors ? "error-outline" : ""}`}
              onChange={onChange}
              value={value}
              onKeyDown={handleKeyDown}
              title={title}
              autoComplete={autoComplete}
              aria-label={ariaLabel}
              aria-describedby={ariaDescribedby}
            />
          ) : (
            <>
              <input
                id={name}
                ref={ref} // Forwarded ref for react-hook-form
                type={type}
                name={name}
                placeholder={placeholder}
                className={`inputs ${errors ? "error-outline" : ""}`}
                onChange={onChange}
                value={value}
                onKeyDown={handleKeyDown}
                title={title}
                autoComplete={autoComplete}
                aria-label={ariaLabel}
                aria-describedby={ariaDescribedby}
                disabled={isDisabled}
                readOnly={readOnly}
              />
              {value && enableClear && (
                <span
                  onClick={handleClear}
                  style={{
                    position: "relative",
                    left: "-14px",
                    bottom: "-12px",
                    top: "50%",
                    transform: "translateY(-50%)",
                    cursor: "pointer",
                    color: "gray",
                    fontSize: "24px",
                    fontWeight: "500",
                  }}
                >
                  ×
                </span>
              )}
            </>
          )}
          {showPasswordToggle && (
            <button
              type="button"
              onClick={onTogglePassword}
              aria-label={name}
              className="passwordToggleBtn"
            >
              {showPassword ? <IoEyeOffOutline /> : <IoEyeOutline />}
            </button>
          )}
        </div>
        {errors && (
          <p className={`error  ${additionalClass}`}>
            {errors?.message ? errors.message : `${name} is ${errors.type}`}
          </p>
        )}
      </>
    );
  },
);

// Adding display name for easier debugging and to resolve ESLint warning
InputFields.displayName = "InputFields";

export default InputFields;
