import { useState, useRef, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import { LuLogOut } from "react-icons/lu";
import {
  logoutActionCreator,
  getUserDetailActionCreator,
} from "../../redux/slices/LoginSlices";
import logo from "../../assets/logo/hines.svg";
import style from "./Header.module.scss";
import { CgProfile } from "react-icons/cg";
import { toast } from "react-toastify";

const Header = () => {
  const dispatch = useDispatch();
  const userProfile = useSelector((state) => state.login.userProfile);
  const token = useSelector((state) => state.login.loginDetail);
  const [openPopOver, setOpenPopOver] = useState(false);
  const [openProduct, setOpenProduct] = useState(false);
  const popoverRef = useRef(null);
  const prodRef = useRef(null);
  const productRef = useRef(null);
  const buttonRef = useRef(null);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("touchstart", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("touchstart", handleClickOutside);
    };
  }, []);

  const Logout = () => {
    toast.dismiss();
    if (Cookies.get("token")) {
      dispatch(
        logoutActionCreator({
          body: {
            refresh: token?.refresh,
          },
        }),
      ).then(() => {
        Cookies.remove("token");
        navigate("/login");
      });
    } else {
      navigate(0);
    }
  };

  const handleClickOutside = (event) => {
    if (
      popoverRef.current &&
      !popoverRef.current.contains(event.target) &&
      buttonRef.current &&
      !buttonRef.current.contains(event.target)
    ) {
      setOpenPopOver(false);
    }
    if (
      prodRef.current &&
      !prodRef.current.contains(event.target) &&
      productRef.current &&
      !productRef.current.contains(event.target)
    ) {
      setOpenProduct(false);
    }
  };

  let profile = JSON.parse(JSON.stringify(userProfile)) || {};

  const togglePopOver = (isProduct = false) => {
    if (isProduct) {
      setOpenProduct(!openProduct);
    } else {
      setOpenPopOver(!openPopOver);
    }
  };

  const fetchUser = async () => {
    const response = await dispatch(
      getUserDetailActionCreator(Cookies.get("token")),
    );
    if (!response.error) {
      navigate("/profile", { state: { user: response.payload } });
      setOpenPopOver(false);
    }
  };

  return (
    <header className={style.header}>
      <div className={style.innerContent}>
        <div className={style.left}>
          <div onClick={() => navigate("/")} className={style.logoContainer}>
            <img src={logo} alt="hines logo" className={style.logo} />
          </div>
          <ul className={style.navLink}>
            <li
              className={`${style.navItems} ${location.pathname == "/properties" ? style.active : ""}`}
            >
              <Link to={"/properties"}>Properties</Link>
            </li>
            <li
              className={`${style.navItems} ${location.pathname == "/budget" ? style.active : ""}`}
            >
              <Link to={"/budget"}>Budget</Link>
            </li>

            <li
              className={`${style.navItems} ${location.pathname == "/products" ? style.active : ""} `}
            >
              <Link to={"/products"}>Products</Link>
            </li>
            {profile?.is_superuser && (
              <>
                <li
                  className={style.product}
                  onMouseEnter={() => togglePopOver(true)}
                  onMouseLeave={() => togglePopOver(true)}
                  ref={productRef}
                  // aria-pressed="true"
                  // aria-expanded={openProduct ? "true" : "false"}
                >
                  <button
                    className={`${style.arrow} ${openProduct ? style.up : style.down}`}
                    aria-label="Expand product menu"
                  />
                  {profile?.is_superuser && openProduct && (
                    <span role="div" className={style.popOver} ref={prodRef}>
                      <ul className={style.popList}>
                        <li
                          className={`${style.subNav} ${location.pathname == "/category" ? style.active : ""} `}
                        >
                          <Link
                            to={"/category"}
                            onClick={() => setOpenProduct(false)}
                          >
                            Category
                          </Link>
                        </li>
                        <li
                          className={`${style.subNav} ${location.pathname == "/vendor" ? style.active : ""}`}
                        >
                          <Link
                            to={"/vendor"}
                            onClick={() => setOpenProduct(false)}
                          >
                            Vendor
                          </Link>
                        </li>
                        <li
                          className={`${style.subNav} ${location.pathname == "/product-groups" ? style.active : ""}`}
                        >
                          <Link
                            to={"/product-groups"}
                            onClick={() => setOpenProduct(false)}
                          >
                            Product Groups
                          </Link>
                        </li>
                      </ul>
                    </span>
                  )}
                </li>
                <li
                  className={`${style.navItems} ${location.pathname == "/users" ? style.active : ""}`}
                >
                  <Link to={"/users"}>Users</Link>
                </li>
              </>
            )}
          </ul>
        </div>
        <div className={style.right}>
          <button
            className={style.userLogo}
            ref={buttonRef}
            onClick={() => togglePopOver()}
          >
            {userProfile?.first_name
              ? userProfile?.first_name[0].toUpperCase()
              : userProfile?.last_name
                ? userProfile?.last_name[0].toUpperCase()
                : userProfile?.email[0].toUpperCase()}
          </button>
          {openPopOver && (
            <div className={style.popOver} ref={popoverRef}>
              <ul className={style.popList}>
                <li>
                  <button onClick={fetchUser} className={style.profile}>
                    <CgProfile />
                    Profile
                  </button>
                </li>
                <li>
                  <button onClick={Logout}>
                    <LuLogOut /> <span>Logout</span>
                  </button>
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
    </header>
  );
};

export default Header;
